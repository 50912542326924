import Home from "./Pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";

import AboutUs from "./Pages/AboutUs";
import Policy from "./Pages/Policy";
import Terms from "./Pages/Terms";
import Contact from "./Pages/Contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/contact-us",
    element: <Contact />,
  },
  {
    path: "/privacy",
    element: <Policy />,
  },

  {
    path: "/about",
    element: <AboutUs />,
  },

  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

function App() {
  return (
    <div className="">
      <HelmetProvider>
        <RouterProvider router={router} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
          ltr={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          hideProgressBar={true}
        />
      </HelmetProvider>
    </div>
  );
}

export default App;
