import React, { useEffect } from "react";
import SEO from "../Components/Seo";
import Footer from "../Components/Nav/Footer";
import Header from "../Components/Nav/Header";
import Show from "../Components/Home/Show";

const Policy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="bg-[#F5F5F5] pt-6">
      <SEO title={""} description={""} name={"Halves Terms"} type={"article"} />
      <Header />

      <div className="min-h-[80vh] lg:max-h-[40vh]    pt-4 pb-28 justify-center items-center flex w-full m-auto max-w-[90%]">
        <div className="flex w-full flex-col justify-center items-center lg:flex-row gap-[20px] lg:gap-[2%]">
          <div className="flex-1 items-center mt-10 w-full md:items-start flex flex-col justify-center gap-5">
            <h2 className="text-[48px] leading-[50px]  lg:text-[54px] 3xl:text-[60px] 4xl:text-[64px] lg:leading-[56px] 3xl:leading-[68.86px] 4xl:leading-[72.86px] font-[700] mb-1 mt-2 text-[#131E3D]  text-center sm:text-left ">
              Privacy Policy
            </h2>

            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5 max-w-[75%] text-[#6D6E73] text-center sm:text-left ">
              Last updated: <span className="font-[700]">Feb 26, 2024</span>
            </h4>
          </div>

          <div className="flex-1 lg:mb-[-25%] min-h-[40vh] lg:min-h-[75vh] h-auto flex gap-4">
            <div className="flex w-full relative justify-end flex-col">
              <img src="/Assets/Policy.png" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className=" py-8 w-full bg-[#FFF]  ">
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            1. Introduction
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              https://halvestco.com is provided by Halvest Financial
              Technologies Limited (“Halvest”).
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Halvest values your privacy and is committed to safeguarding your
              personal information. All personal data that you provide us will
              be protected and kept confidential among our affiliates,
              representatives, and privies.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Throughout this policy, the terms “we”, “us” and “our” refer to
              Halvest. This Privacy Policy explains how we collect, use, share
              and protect your personal data or any personal data that you share
              with us in connection with your relationship with us as a user or
              potential user. It also sets out your rights in relation to your
              information and who you may contact for further information.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              The Privacy Policy applies to all our clients, potential clients,
              consultants, partners, and every other person we hold information
              about. Essentially, this Privacy Policy applies to you if you
              visit or use our website or mobile application (together the
              “Platform”), access our services or interact with us in any legal,
              contractual or business purpose, and/or perform any other
              activities that form part of our business. You agree to this
              Privacy Policy by visiting our Platform and when you use our
              services.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Your use of our services, and any dispute over privacy is subject
              to this Policy and our Terms of Service, including its applicable
              limitations on damages and the resolution of disputes. Our Terms
              of Service are incorporated by reference into this Policy.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Our Platform and services are not directed at you if we are
              prohibited by any law of any jurisdiction from making the
              information on our Platform available to you and is not intended
              for any use that would be contrary to local law or regulation.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            2. Consent
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              You accept this privacy policy when you give consent upon
              accessing our Platform, or using our services, content, features,
              technologies or functions offered on our website, digital
              platforms or visit any of our offices for official or non-official
              purposes.If you do not agree with the terms outlined in this
              Privacy Policy,
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              it is necessary to refrain from accessing our services or Platform
              or providing any information to us. Note that you may choose to
              withdraw your consent at any time. The withdrawal of your consent
              will prevent us from providing our services to you.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            3. Age Restriction
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              You affirm that you are over the age of 18 years and have the
              right to contract in your own name, and that you have read the
              above authorisation and fully understand its contents.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            4. Information We Collect.
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              There are two categories of information we collect. We collect a
              variety of information from our users and visitors to our website.
              As described below, some information is automatically collected
              when you visit our website, and some you provide to us when
              filling out a form or communicating with us.There are two
              categories of information we collect. We collect a variety of
              information from our users and visitors to our website. As
              described below, some information is automatically collected when
              you visit our website, and some you provide to us when filling out
              a form or communicating with us.
            </h4>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] ">
                <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  4.1
                </h4>
              </div>
              <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                Information Collected Automatically: Whenever you visit our
                website, our web servers automatic ally collect non-personal
                information such as the domain name of the internet access
                provider, the internet protocol address used to connect the
                computer to the internet, the average time spent on our website,
                pages viewed, information searched for, access times, and other
                relevant statistics.
              </h4>
            </div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] ">
                <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  4.2
                </h4>
              </div>
              <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                Information You Provide Us: If you provide us with personal
                information, by contacting us, or subscribing to our services we
                collect the following personal information:
              </h4>
            </div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] "></div>
              <div className="flex gap-5 w-full sm:w-[80%] mb-5">
                <div className="min-w-[50px] ">
                  <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                    4.2.1
                  </h4>
                </div>
                <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  Contact information such as address, email address, country
                  and telephone number;
                </h4>
              </div>
            </div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] "></div>
              <div className="flex gap-5 w-full sm:w-[80%] mb-5">
                <div className="min-w-[50px] ">
                  <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                    4.2.2
                  </h4>
                </div>
                <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  Profile Data such as your username, password and means of
                  identification;
                </h4>
              </div>
            </div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] "></div>
              <div className="flex gap-5 w-full sm:w-[80%] mb-5">
                <div className="min-w-[50px] ">
                  <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                    4.2.3
                  </h4>
                </div>
                <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  Financial Information such as bank account details and payment
                  card details
                </h4>
              </div>
            </div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] "></div>
              <div className="flex gap-5 w-full sm:w-[80%] mb-5">
                <div className="min-w-[50px] ">
                  <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                    4.2.4
                  </h4>
                </div>
                <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  Any other information you provide to us.
                </h4>
              </div>
            </div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] ">
                <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  4.3
                </h4>
              </div>
              <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                We may collect sensitive or special categories of personal data
                as part of Know-Your-Customer (KYC) and Anti-Money Laundering
                (AML) checks that we must conduct before accepting you as a user
              </h4>
            </div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] ">
                <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  4.4
                </h4>
              </div>
              <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                We may also collect personal data about you indirectly from
                third parties including but not limited to (i) third parties
                such as providers of KYC and AML services to help us meet our
                legal obligations and verify your identity where we provide you
                with our services; (ii) background check providers; (iii) third
                party service providers that help us operate our platforms; (iv)
                your banks and financial institutions; (v) credit reference
                organisations.
              </h4>
            </div>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            5. Using Your Personally Identifiable Information
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              “Personally Identifiable Information” means any information that
              (a) identifies or can be used to identify, contact, or locate the
              person to whom such information pertains, or (b) from which
              identification or contact information of an individual person can
              be derived.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              We primarily collect your information to ensure that we provide
              the most efficient service to you, monitor the use and improve our
              Platform and other legitimate interests. Your information will
              solely be used and disclosed for the following purposes:
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.1
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To help us verify your identity;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.2
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To carry out our obligations ensuing from any contracts entered
              into between you and us;
            </h4>
          </div>

          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.3
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To provide you with the products, services and information you
              request from us;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.4
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To assist you with enquiries and improve our customer service;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.5
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To assist us in carrying out marketing analysis and customer
              profiling (including transactional information), conduct research,
              including creating statistical and testing information;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.6
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To allow us to communicate with you in any way (including e-mail,
              telephone, visit, and text or multimedia messages);
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.7
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              For our billing and account purposes;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.8
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To help prevent and detect fraud or loss;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.9
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To update our records;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.10
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To make recommendations and suggestions to you about services
              offered by us unless you have previously asked us not to do so;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.11
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To send you service or support messages, such as updates, security
              alerts, email notifications and /or newsletters;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.12
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To conduct investigations and risk assessments;
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                5.13
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              For compliance with legal and regulatory obligations.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            6. Data Accuracy
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Your personal data must be accurate and kept up to date. In this
              regard, Halvest shall ensure that any data it collects and/or
              processes is accurate and not misleading in a way that could be
              harmful to you; make efforts to keep your personal data updated
              where reasonable and applicable; and make timely efforts to
              correct or erase your personal data when inaccuracies are
              discovered.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            7. Other Information We Collect
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Other information which may be automatically collected from you
              when you visit our website include; domain name of your internet
              service provider, the internet protocol address used to connect
              the computer to the internet, the average time spent on our
              website, pages viewed, information searched for, access times,
              your geographical location, operating system, referral source, and
              other relevant statistics.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              We may also collect information from you using cookies and other
              analytical tools especially when you use our products and
              services. More details are provided below in our section on
              Cookies
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            8. Data Confidentiality
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Your information is regarded as confidential and will not be
              divulged to any third party, except under legal and/or regulatory
              conditions. You have the right to request copies of any and all
              information we keep on you if such requests are made in compliance
              with applicable laws.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              While we are responsible for safeguarding the information
              entrusted to us, your role in fulfilling confidentiality duties
              includes but is not limited to, adopting, and enforcing
              appropriate security measures such as non-sharing of passwords and
              other platform login details, adherence with physical security
              protocols on our premises, dealing with only authorized officers
              of Halvest.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            9. Disclosures
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Your information is regarded as confidential and will not be
              divulged to any third party, except under legal and/or regulatory
              conditions. You have the right to request copies of any and all
              information we keep on you if such requests are made in compliance
              with applicable laws.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              While we are responsible for safeguarding the information
              entrusted to us, your role in fulfilling confidentiality duties
              includes but is not limited to, adopting, and enforcing
              appropriate security measures such as non-sharing of passwords and
              other platform login details, adherence with physical security
              protocols on our premises, dealing with only authorized officers
              of Halvest.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                9.1
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              We will not sell, publish, or disclose to third parties your
              personally identifiable information collected on our Platform,
              through our servers or otherwise obtained by us, other than to
              provide our services and as set forth in this Privacy Policy. We
              may share generic aggregated demographic information not linked to
              any personally identifiable information regarding visitors and
              users with our business partners, trusted affiliates, professional
              advisers, and advertisers for the purposes outlined above. We may
              share your information with these third parties for those limited
              purposes if you have given us your permission in compliance with
              the applicable data protection laws including the Nigerian Data
              Protection Act 2023, the Nigeria Data Protection Regulation 2019
              (NDPR), the General Data Protection Regulation 2018 and other data
              protection laws that apply to the processing of your data (Data
              Protection Laws).
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                9.2
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              We may request and provide information about you from and to third
              parties to provide our services.
            </h4>
          </div>

          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                9.3
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              We will notify you as soon as we become aware of a harmful data
              breach which may result in a risk of your rights and freedom.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                9.4
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              You have the right to request an erasure of your data at any time.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                9.5
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              We will notify you if we are transferring your data.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                9.6
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              You may request at any time that we halt further dissemination of
              your data or cease to use your data.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                9.7
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              If you submit content in a public forum or a social media post, or
              use a similar feature on our Platform, that content is publicly
              visible.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                9.8
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              We may disclose Personally Identifiable Information if required to
              do so by law or in the good faith belief that such action is
              necessary to (a) conform with the requirements of the law or
              comply with legal process served on us, or (b) act in urgent
              circumstances to protect the personal safety of users of our
              service or members of the public.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                9.9
              </h4>
            </div>
            <h4 className=" text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              To the extent practicable and legally permitted, we will attempt
              to advise you prior to any such disclosure, so that you may seek a
              protective order or other relief limiting such disclosure.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            10. Transfer of Personal Data
          </h2>

          <div className="flex mt-6 gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                10.1
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              Third Party ProcessorWe may engage the services of third parties
              in order to process your personal data. The processing by such
              third parties shall be governed by a written contract with Halvest
              to ensure adequate protection and security measures are put in
              place by the third party for the protection of your personal data
              in accordance with the terms of this policy and Data Protection
              Laws. Transfer of Personal Data to Foreign Country
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                10.2
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              Transfer of Personal Data to Foreign Country
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] "></div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] ">
                <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  10.2.1
                </h4>
              </div>
              <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                Where your personal data is to be transferred to a country
                outside Nigeria, Halvest shall put adequate measures in place to
                ensure the security of such data. In particular, Halvest shall
                ensure that such transfer is in accordance with Data Protection
                Laws and that the foreign country receiving such data has
                adequate data protection laws. We will also always document the
                basis of such international transfers and the adequacy of data
                protection relied upon
              </h4>
            </div>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            11. Your Rights
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Subject to certain limitations and exceptions, you are entitled to
              the following principal rights:
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                11.1
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              You have the right to be notified if we are transferring your
              personal information.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                11.2
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              You have the right to request an erasure of your personal
              information at any time.
            </h4>
          </div>

          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                11.3
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              You have the right to request that we rectify inaccurate personal
              information.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                11.4
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              You may request at any time that we halt further dissemination of
              your data or cease to use your personal information.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                11.5
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              You have the right to request for copies of your personal
              information.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                11.6
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              You have the right to file a complaint with the Data Protection
              Commission if you are unsatisfied with how we process your data.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                11.7
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              You may also withdraw your consent at any time by sending a
              withdrawal notification to the email provided below. Kindly note
              that a withdrawal of consent will not affect already processed
              data and we may retain your information as required by law.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            12. Platform Security
          </h2>

          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorised access or disclosure, we have put in
              place suitable physical, electronic andmanagerial procedures to
              safeguard and secure the information we collect online.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              We use encryption tools when accepting and transmitting delicate
              visitor information through our Platform. Some of the other
              safeguards we use are firewalls and physical access controls to
              our data centres, and information access authorization controls.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            13. Training
          </h2>

          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              We shall ensure that employees who collect, access and process
              your personal data receive adequate data privacy and protection
              training in order to develop the necessary knowledge, skills and
              competence required to effectively manage the compliance framework
              under this policy and Data Protection Laws with regard to the
              protection of personal data.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              On an annual basis, we shall develop a capacity-building plan for
              our employees on data privacy and protection in accordance with
              Data Protection Laws.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            14. Use of Cookies
          </h2>

          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              We use cookies to identify you as a user and make your user
              experience easier, customise our services, content and
              advertising; help you ensure that your account security is not
              compromised, mitigate risk and prevent fraud; and to promote trust
              and safety on our website.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Cookies allow our servers to remember your account log-in
              information when you visit our website, IP addresses, date and
              time of visits, monitor web traffic and prevent fraudulent
              activities. If your browser or browser add-on permits, you have
              the choice to disable cookies on our website; however, this may
              limit your ability to use our website.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            15. The Data We Retain
          </h2>

          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              We will retain your information for as long as needed to provide
              you with our services, comply with our legal and statutory
              obligations or verify your information with a financial
              institution.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              We are obligated to retain the data you provide to us in order to
              process transactions, ensure settlements, make refunds, identify
              fraud and be in compliance with laws and regulatory guidelines
              applicable to us, our banking providers and credit card
              processors.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            16. Data Breach Management Procedure
          </h2>

          <div className="flex mt-6 gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                16.1
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              In the event where there is any accidental or unlawful
              destruction, processing, loss, alteration, unauthorized disclosure
              of or access to your personal data, we shall:
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] "></div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] ">
                <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  16.1.1
                </h4>
              </div>
              <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                notify you within 24 hours of the occurrence of the data breach;
              </h4>
            </div>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] "></div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] ">
                <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  16.1.2
                </h4>
              </div>
              <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                properly investigate the breach and take the necessary steps to
                mitigate such breach;
              </h4>
            </div>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] "></div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] ">
                <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  16.1.3
                </h4>
              </div>
              <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                identify remediation requirements and track the resolution of
                such breach;
              </h4>
            </div>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] "></div>
            <div className="flex gap-5 w-full sm:w-[80%] mb-5">
              <div className="min-w-[50px] ">
                <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                  16.1.4
                </h4>
              </div>
              <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                and notify the appropriate regulatory authority, where necessary
              </h4>
            </div>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            17. Links to Third Party Websites
          </h2>

          <div className="flex gap-5 w-full sm:w-[80%] mb-5 mt-6">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                17.1
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              Our Platform may contain links to third-party websites or services
              that are not owned or controlled by us.
            </h4>
          </div>
          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                17.2
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              We have no control over, and assume no responsibility for, the
              content, privacy policies, or practices of any third-party
              websites or services. You further acknowledge and agree that we
              shall not be responsible or liable, directly or indirectly, for
              any damage or loss caused or alleged to be caused by or in
              connection with use of or reliance on any such content, goods or
              services available on or through any such websites or services.
            </h4>
          </div>

          <div className="flex gap-5 w-full sm:w-[80%] mb-5">
            <div className="min-w-[50px] ">
              <h4 className="text-[20px] 3xl:text-[24px]   leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
                17.3
              </h4>
            </div>
            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400]  text-[#6D6E73] ">
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party websites or services that you
              visit.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            18. Limitation of Liability
          </h2>

          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              We exercise reasonable efforts to safeguard the security and
              confidentiality of your personal data; however, we will not be
              liable for unauthorised disclosure of personal data that occurs
              through no fault of ours.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            19. Changes to this Privacy Policy
          </h2>

          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Changes may be made to this Privacy Policy from time. Whenever
              such changes are made, we will notify you. These changes will take
              effect immediately after you have been notified.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            20. Contact
          </h2>

          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              If you would like more information or have any comments or
              questions on our privacy policy, please contact us at
              [hello@halvestco.com | +2348029093009]
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              This policy is effective as of Feb 26, 2024.
            </h4>
          </div>
        </div>
      </div>
      <Show />
      <Footer />
    </div>
  );
};

export default Policy;
