import React, { useEffect, useState } from "react";
import Fast from "../../Assets/Icons/fast.png";
import logo from "../../Assets/Logo/Logo.png";
import showoff from "../../Assets/Images/showoff.png";
import { Box, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "../Inputs/Button";
import { formatAmount } from "../../Utils/formatAmount";

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "#31D3C6",
  height: 8,
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#31D3C6",
    border: "2px solid #fff",
    "&:focus, &:hover, &$active": {
      boxShadow: "0 0 0 8px rgba(105, 91, 252, 0.16)",
    },
  },
  "& .MuiSlider-rail": {
    height: 8,
    backgroundColor: "#F0F0F5",
  },
  "& .MuiSlider-track": {
    height: 8,
    backgroundColor: "#31D3C6",
  },
}));

const Partners = () => {
  const [rangeValue, setRangeValue] = useState(1000000);
  const [intrestRangeValue, setIntrestRangeValue] = useState(15);
  const [termRangeValue, setTermRangeValue] = useState(1);
  const [calculator, setCalculator] = useState(0);
  const handleChange = (event, newValue) => {
    setRangeValue(newValue);
  };
  const handleChangeInterest = (event, newValue) => {
    setIntrestRangeValue(newValue);
  };
  const handleChangeTerm = (event, newValue) => {
    setTermRangeValue(newValue);
  };

  const getValue = (termRangeValue, amount) => {
    let result = amount;
    for (let x = 0; x < termRangeValue; x++) {
      result = result * (1 + 0.34);
    }

    return result;
  };

  useEffect(() => {
    setCalculator(getValue(termRangeValue, rangeValue));
  }, [termRangeValue, rangeValue]);

  return (
    <div className="min-h-[80vh]  bg-[#FFF] pt-2 pb-1 w-full ">
      <div className=" w-full gap-[2%]">
        {/* <h2 className="text-[#131E3D] font-[700] text-center w-full text-[40px] 3xl:text-[48px]">
          Halvest Partners
        </h2> */}
        {/* <div className="flex gap-10 items-center my-6 justify-center ">
          <img src={logo} className=" w-[152.92px]" />
          <img src={logo} className=" w-[152.92px]" />
          <img src={logo} className=" w-[152.92px]" />
          <img src={logo} className=" w-[152.92px]" />
        </div> */}
        <div
          className="max-w-[90%] mx-auto my-40
         "
        >
          <div className="w-full flex flex-col lg:flex-row gap-16 lg:gap-20 ">
            <div className="flex-1  flex flex-col 2xl:max-h-[88vh] 3xl:h-[1500px]  justify-between gap-16 bg-[#F5EFFF]  overflow-hidden rounded-[30px]  pt-8">
              <div className="w-full  px-[6%]">
                <h4 className=" font-[700] text-[#6935D3]  text-[44px] leading-[48px] md:text-[54px] 3xl:text-[60px] 4xl:text-[64px] md:leading-[56px] 3xl:leading-[68.86px] 4xl:leading-[72.86px] ">
                  How hard can your money work for you?
                </h4>
              </div>
              <img src={showoff} className="w-full  " />
            </div>

            <div className="flex-1 flex flex-col bg-[#FFF] rounded-[30px]  justify-center py-10 border-[#D9D9D9]  border-[1px] ">
              <div className="w-full px-[6%]">
                <h4 className=" font-[450] text-[#6D6E73]  text-[24px] leading-[28px] sm:text-[26px] sm:leading-[34px] xl:text-[30px] 3xl:text-[38px] 4xl:text-[42px] xl:leading-[38px] 3xl:leading-[50.6px] 4xl:leading-[54px] ">
                  You can build wealth ethically with compounding Investment
                  profits
                </h4>
                <div className="bg-[#F5FFFE] mt-6 4xl:mt-10 rounded-[32.6px] p-[4%] w-full">
                  <div>
                    <h4 className=" font-[450] mb-1 text-[#5F626E]  text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                      If you invested:
                    </h4>
                    <Box sx={{ width: "100%" }}>
                      <CustomSlider
                        aria-label="Investment Range"
                        value={rangeValue}
                        onChange={handleChange}
                        min={1000000}
                        max={100000000}
                        valueLabelDisplay="auto"
                        getAriaValueText={(value) => `${value}%`}
                      />
                    </Box>

                    <div className="flex justify-between  mb-6 mt-[-8px] items-center ">
                      <h4 className=" font-[450]  text-[#232323]  text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                        ₦{formatAmount(rangeValue, 0)}
                      </h4>
                      {/* <h4 className=" font-[450] text-[#232323]  text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                        ₦1,000,000
                      </h4> */}
                    </div>
                  </div>
                  <div className=" w-full mt-8">
                    <h4 className=" font-[450] mb-1 text-[#5F626E]  text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                      For:
                    </h4>
                    <Box sx={{ width: "100%" }}>
                      <CustomSlider
                        aria-label="Investment Range"
                        value={termRangeValue}
                        onChange={handleChangeTerm}
                        min={1}
                        max={4}
                        valueLabelDisplay="auto"
                        getAriaValueText={(value) => `${value}%`}
                      />
                    </Box>

                    <div className="flex justify-between  mb-6 mt-[-8px] items-center ">
                      <h4 className=" font-[450]  text-[#232323]  text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                        {termRangeValue} year{termRangeValue === 1 ? "" : "s"}
                      </h4>
                      {/* <h4 className=" font-[450] text-[#232323]  text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                        ₦1,000,000
                      </h4> */}
                    </div>
                  </div>
                  {/* <div className=" flex flex-wrap justify-between w-full mt-8">
                    <div className=" w-full  sm:w-auto min-w-[226.06px]">
                      <h4 className=" font-[450] mb-1 text-[#5F626E]  text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                        Interest Rate
                      </h4>
                      <Box sx={{ width: "100%" }}>
                        <CustomSlider
                          aria-label="Investment Range"
                          value={intrestRangeValue}
                          onChange={handleChangeInterest}
                          min={0}
                          max={100}
                          // valueLabelDisplay="auto"
                          getAriaValueText={(value) => `${value}%`}
                        />
                      </Box>
                      <h4 className=" font-[450] text-[#232323] mb-6 mt-[-8px] text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                        {intrestRangeValue}%
                      </h4>
                    </div>
                    <div className="w-full sm:w-auto min-w-[226.06px]">
                      <h4 className=" font-[450] mb-1 text-[#5F626E]  text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                        Term
                      </h4>
                      <Box sx={{ width: "100%" }}>
                        <CustomSlider
                          aria-label="Investment Range"
                          value={termRangeValue}
                          onChange={handleChangeTerm}
                          min={0}
                          max={12}
                          valueLabelDisplay="auto"
                          getAriaValueText={(value) => `${value}%`}
                        />
                      </Box>
                      <h4 className=" font-[450] text-[#232323] mb-6 mt-[-8px] text-[16px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                        {termRangeValue} month{termRangeValue === 1 ? "" : "s"}
                      </h4>
                    </div>
                  </div> */}
                </div>
                <div className="bg-[#F9F6FF] rounded-[32.6px] px-[4%] py-6 w-full">
                  <h4 className=" font-[450] text-[#5F626E]  text-[22px] 3xl:text-[28px] 4xl:text-[32px] leading-[24px] 3xl:leading-[30.24px] 4xl:leading-[34px]">
                    Total Value with Halvest
                  </h4>
                  <h5 className="text-[#232323] my-1 4xl:mt-2 font-[600]   text-[48px] 3xl:text-[60px] 4xl:text-[64px] leading-[66px] 3xl:leading-[72.24px] 4xl:leading-[76px] ">
                    ₦{formatAmount(calculator, 0)}
                  </h5>
                  <h4 className="text-[12px] leading-[14.8px]   font-[400] text-[#5F626E]  tracking-[1px] ">
                    * This calculation is based on the average performance of
                    our business financing projects since 2021 and is net of
                    fees. Past performance does not guarantee future outcomes,
                    and actual results may vary.
                  </h4>
                </div>

                <Button
                  onClick={() => {}}
                  classes={
                    "mt-6 4xl:text-[21.12px] 4xl:mt-10 4xl:h-[77px] !w-[244px]"
                  }
                  link="https://app.halvestco.com/signin"
                  title={"Start investing"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
