import React, { useEffect } from "react";
import SEO from "../Components/Seo";
import Header from "../Components/Nav/Header";

import Hero from "../Components/Home/Hero";
import Partners from "../Components/Home/Partners";
import GetStarted from "../Components/Home/GetStarted";
import Explore from "../Components/Home/Explore";
import MoneyIsSafe from "../Components/Home/MoneyIsSafe";
import WhyInvest from "../Components/Home/WhyInvest";
import Hear from "../Components/Home/Hear";
import Show from "../Components/Home/Show";
import Footer from "../Components/Nav/Footer";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="bg-[#F5F5F5] pt-6">
      <SEO title={""} description={""} name={"Halvest"} type={"article"} />
      <Header />
      <Hero />
      <Partners />
      <GetStarted />
      <Explore />
      <MoneyIsSafe />
      <WhyInvest />
      <Hear />
      <Show />
      <Footer />
    </div>
  );
};

export default Home;
