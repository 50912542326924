import React, { useEffect } from "react";
import SEO from "../Components/Seo";
import Footer from "../Components/Nav/Footer";
import Header from "../Components/Nav/Header";
import Show from "../Components/Home/Show";

const Terms = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="bg-[#F5F5F5] pt-6">
      <SEO title={""} description={""} name={"Halves Terms"} type={"article"} />
      <Header />
      <div className="min-h-[80vh] lg:max-h-[40vh] lg:mb-[-7%]  overflow-hidden pt-4 pb-28 justify-center items-center flex w-full m-auto max-w-[90%]">
        <div className="flex w-full flex-col justify-center items-center lg:flex-row gap-[20px] lg:gap-[2%]">
          <div className="flex-1 items-center mt-10 w-full md:items-start flex flex-col justify-center gap-5">
            <h2 className="text-[48px] leading-[50px]  lg:text-[54px] 3xl:text-[60px] 4xl:text-[64px] lg:leading-[56px] 3xl:leading-[68.86px] 4xl:leading-[72.86px] font-[700] mb-1 mt-2 text-[#131E3D]  text-center sm:text-left ">
              Terms of Use
            </h2>

            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5 max-w-[75%] text-[#6D6E73] text-center sm:text-left ">
              Last updated: <span className="font-[700]">Feb 26, 2024</span>
            </h4>
          </div>

          <div className="flex-1 min-h-[40vh] lg:min-h-[75vh]  h-auto flex gap-4">
            <div className="flex w-full relative justify-end flex-col">
              <img src="/Assets/Terms.png" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className=" py-8 w-full bg-[#FFF]  ">
        <div className="pb-4 pt-16 px-4  w-full m-auto max-w-[90%]   ">
          <h4 className="text-[20px] w-full sm:w-[100%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
            Please read these Terms of Service (“Terms”), which set forth the
            legally binding terms and conditions between you (“You”) and Halvest
            Financial Technologies Ltd (“Halvest” or the “Company” or “we”,
            “our” or “us”). It governs your access to and the use of Halvest’s
            website, mobile application (together the “Platform”). Our
            collection and use of personal information in connection with your
            use of our Services are described in our Privacy Policy.
          </h4>
          <h4 className="text-[20px] w-full sm:w-[100%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
            Your access to use our Services is conditioned on your acceptance of
            and compliance with these Terms. These Terms, all applicable laws,
            conditions and policies referenced here apply to all visitors, users
            and others who access or use our Service. Our website is not
            directed at you if we are prohibited by any law of any jurisdiction
            from making the information on our website available to you and is
            not intended for any use that would be contrary to your local law or
            regulation.
          </h4>
          <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
            By accessing or using our Services, you agree to be bound by these
            Terms. If you disagree with any part of the Terms, then you may not
            access our Services.
          </h4>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            1. Definitions
          </h2>
          <div className="mt-8 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              <span className="font-[700]">“Account”</span> <br />
              means a unique personified account registered in the name of the
              User and which contains details of the User’s transactions or
              operations on the Platform.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              <span className="font-[700]">“Platform”</span> <br />
              means the Halvest website accessible at https://halvestco.com/ and
              the Halvest mobile application.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              <span className="font-[700]">“Service(s)”</span> <br />
              means all services provided to you by Halvest through the
              Platform.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              <span className="font-[700]">“User”</span> <br />
              means a person who completes the registration process for the
              purpose of making use of our Services including visitors, members
              and non-members of the Halvest community.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              <span className="font-[700]">“User-Submitted Content”</span>{" "}
              <br />
              means all information and data (including text, images, photos,
              videos, audio, and documents) or any other content in any media
              and format that you, directly or indirectly, provide or make
              available to us in connection with your use of the Platform
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            2. Acceptance of Terms
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              The Service is offered subject to acceptance of all the terms and
              conditions contained in these Terms and all other operating rules,
              policies and procedures that may be published on the Platform by
              Halvest, which are incorporated by reference, including operating
              rules, policies and procedures of third-party service providers to
              the Platform that are referenced herein. These Terms apply to
              every User of the Service.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              In addition, the investment opportunities offered through the
              Platform may be subject to additional terms and conditions adopted
              by Halvest. Your participation in those investment opportunities
              is subject to those additional terms and conditions, which are
              incorporated into these Terms by this reference.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Halvest reserves the right, at its sole discretion, to modify or
              replace these Terms from time to time by posting the updated terms
              on the Platform. It is your responsibility to check the terms
              periodically for changes. If you object to such changes, your sole
              recourse will be to cease using the Platform and the Service. Your
              continued use of the Service following the posting of changes to
              the Terms will indicate your acknowledgement of such changes and
              agreement to be bound by the terms and conditions of such changes.
            </h4>
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Halvest reserves the right to change, suspend, or discontinue the
              Service (including but not limited to, the availability of any
              feature, database, or content) at any time forany reason. Halvest
              may also impose limits on certain features and Services or
              restrict your access to parts of or the entire Platform without
              notice or liability.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            3. Accounts
          </h2>
          <div className="mt-6 w-full ">
            <div className="flex">
              <div className="min-w-[50px]">
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  A
                </h4>
              </div>
              <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                To access our Services as a User, you are required to sign up on
                the Platform, create an account (“Halvest Account”) by providing
                your name, email address, phone number, means of identification,
                password and any other information we require from you to
                process or create your Halvest Account. You represent and
                warrant that you have the authority to legally bind the entity
                you are signing up and grant us all permissions and licenses
                provided in these Terms.
              </h4>
            </div>
            <div className="mt-6 w-full ">
              <div className="flex">
                <div className="min-w-[50px]">
                  <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                    B
                  </h4>
                </div>
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  Upon successfully creating a Halvest Account, you will be
                  granted access to the Platform.
                </h4>
              </div>
            </div>

            <div className="mt-6 w-full ">
              <div className="flex">
                <div className="min-w-[50px]">
                  <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                    C
                  </h4>
                </div>
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  You must provide us with information that is accurate,
                  complete, and current at all times. Failure to do so
                  constitutes a breach of these Terms, which may result in
                  immediate termination of your account on our Platform and
                  access to our Services.
                </h4>
              </div>
            </div>

            <div className="mt-6 w-full ">
              <div className="flex">
                <div className="min-w-[50px]">
                  <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                    D
                  </h4>
                </div>
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  You are responsible for safeguarding the password that you use
                  to access the Services on and for any activities or actions
                  under your password, whether your password is with our Service
                  or a third-party service.
                </h4>
              </div>
            </div>

            <div className="mt-6 w-full ">
              <div className="flex">
                <div className="min-w-[50px]">
                  <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                    E
                  </h4>
                </div>
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  You agree not to disclose your password to any third party.
                  You must notify us immediately upon becoming aware of any
                  breach of security or unauthorised use of your Halvest
                  Account.
                </h4>
              </div>
            </div>

            <div className="mt-6 w-full ">
              <div className="flex">
                <div className="min-w-[50px]">
                  <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                    F
                  </h4>
                </div>
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  You shall not use as a username, the name of another person or
                  entity or that is not lawfully available for use, a name or
                  trademark that is subject to any rights of another person or
                  entity other than you without appropriate authorisation, or a
                  name that is otherwise offensive, vulgar or obscene.
                </h4>
              </div>
            </div>

            <div className="mt-6 w-full ">
              <div className="flex">
                <div className="min-w-[50px]">
                  <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                    G
                  </h4>
                </div>
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  You are liable for any and all activities conducted through
                  your Halvest Account, unless such activities are not
                  authorised by you and you are not otherwise negligent (such as
                  failing to report the unauthorised use or loss of your
                  credentials).
                </h4>
              </div>
            </div>

            <div className="mt-6 w-full ">
              <div className="flex">
                <div className="min-w-[50px]">
                  <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                    H
                  </h4>
                </div>
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  You agree to strictly observe the security and authentication
                  procedures of the Services and you will log out from the
                  website by taking proper steps at the end of every visit.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            4. Membership
          </h2>
          <div className="mt-6 w-full ">
            <div className="flex">
              <div className="min-w-[50px]">
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  4.1
                </h4>
              </div>
              <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                Users may choose to use the Services on the Platform as a
                premium member or basic member of the Halvest Community, if You
                choose to become a premium member of the Halvest community, You
                agree to pay a membership fee as required by Halvest in
                consideration for accessing additional services as described in
                the membership plan by Halvest.
              </h4>
            </div>
          </div>
          <div className="mt-6 w-full ">
            <div className="flex">
              <div className="min-w-[50px]">
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  4.2
                </h4>
              </div>
              <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                Basic members are allowed to participate in investment
                opportunities only after being vetted and accepted for
                participation by Halvest.
              </h4>
            </div>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            5. Investment Payments
          </h2>
          <div className="mt-6 w-full ">
            <div className="flex">
              <div className="min-w-[50px]">
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  5.1
                </h4>
              </div>
              <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                When a User decides on participating in an investment
                opportunity, the payment for such investment shall be made
                through a secured third-party payment processor. Such payment
                processor may have several payment methods which may be subject
                to additional terms and conditions. Please review such terms and
                conditions before using the payment methods.
              </h4>
            </div>
          </div>
          <div className="mt-6 w-full ">
            <div className="flex">
              <div className="min-w-[50px]">
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  5.2
                </h4>
              </div>
              <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                Payouts on investments are made by Halvest to Users through a
                designated bank account provided by such User.
              </h4>
            </div>
          </div>
        </div>

        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            6. No Warranty as to Continuous Accuracy
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              Information provided on the Platform is believed to be accurate
              and up-to-date when published. However, Halvest cannot guarantee
              that information will be accurate, complete and current at all
              times. All information on the Platform is subject to modification
              from time to time. Opinions expressed, whether generally or on the
              performance of individual investments or the likely returns on
              certain investment, represent the views of Halvest at the time of
              preparation. They are subject to change and should not be
              interpreted as investment advice.
            </h4>
          </div>
        </div>
        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            7. No Offer for Certain Jurisdictions
          </h2>
          <div className="mt-6 w-full ">
            <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
              The Platform contains information covering different investment
              opportunities managed and registered in different jurisdictions.
              The information on the Platform does not constitute either an
              offer to sell, a solicitation or an offer to buy in a country in
              which a type of offer or solicitation is unlawful, or in whicha
              person making such an offer or solicitation does not hold the
              necessary authorisation to do so. It is also not directed at
              investors, in those cases where the law prohibits this type of
              offer or solicitation from being made. Accordingly, Users
              accessing the information on the Platform are responsible for
              ascertaining the legal requirements, which would affect their
              acquisition of any investment, including any foreign exchange
              control requirements.
            </h4>
          </div>
        </div>

        <div className="py-4 px-4  w-full m-auto max-w-[90%]   ">
          <h2 className="text-[#131E3D] sm:w-[80%] leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] w-full  text-[40px] 3xl:text-[48px]">
            8. Alerts, Notifications & Service Information
          </h2>
          <div className="mt-6 w-full ">
            <div className="flex">
              <div className="min-w-[50px]">
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  8.1
                </h4>
              </div>
              <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                By creating a User Account, you automatically sign up for
                various types of alerts via email and mobile notification. When
                logged in, you may customize, modify and in some cases
                deactivate alerts by adjusting the settings accordingly. These
                communications may include your name, or email address and
                information about your Account or investment activities. Anyone
                with access to your email or mobile device will be able to view
                these alerts. It is your responsibility to update or change the
                e-mail address registered with Halvest, as appropriate. If you
                become aware of any unauthorised use of your information, please
                contact Halvest.
              </h4>
            </div>
          </div>
          <div className="mt-6 w-full ">
            <div className="flex">
              <div className="min-w-[50px]">
                <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                  8.2
                </h4>
              </div>
              <h4 className="text-[20px] w-full sm:w-[80%] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5  text-[#6D6E73]">
                Further, by registering with Halvest you are aware and consent
                that electronic signatures and electronic documents will be used
                instead of paper documents. You agree and are giving consent to
                electronic delivery of all communications, information,
                documents, data and records regarding your account in connection
                with Services provided by Halvest. Your consent will be
                effective immediately and will remain in effect until either the
                Halvest or You revoke it. You understand that it may take up to
                three days to process a revocation of consent to electronic
                communications and you may receive electronic notifications in
                the interim. You may revoke or restrict consent to electronic
                delivery of Halvest communications at any time by notifying Us.
                If you revoke or restrict consent to electronic delivery,
                Halvest, at its discretion, may restrict your account, or close
                your account and terminate access to Platform.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Show />
      <Footer />
    </div>
  );
};

export default Terms;
