import React from "react";
import Loader from "../Others/Loader";

const Button = ({
  title,
  onClick,
  loading,
  link,
  classes,
  allClasses,
  outLine,
}) => {
  return link ? (
    <a target="_blank" rel="noreferrer" href={link}>
      <button
        className={
          allClasses
            ? allClasses
            : `transition duration-[800ms] ease-in-out hover:opacity-[0.85]  cursor-pointer items-center flex justify-center text-[16.61px] font-[500] rounded-[8.24px] border-[0.77px] min-w-[124.41px] h-[54.5px]  ${classes} ${
                outLine
                  ? "border-[#6935D3] border-[0.77px] text-[#6935D3] "
                  : "text-[#FFFFFF] bg-[#6935D3] border-[0.77px] border-transparent"
              }`
        }
      >
        {loading ? <Loader /> : <h2>{title}</h2>}
      </button>
    </a>
  ) : (
    <div
      onClick={onClick}
      className={
        allClasses
          ? allClasses
          : `transition duration-[800ms] ease-in-out hover:opacity-[0.85] cursor-pointer items-center flex justify-center text-[16.61px] font-[500] rounded-[8.24px] border-[0.77px] min-w-[124.41px] h-[54.5px]  ${classes} ${
              outLine
                ? "border-[#6935D3] border-[0.77px] text-[#6935D3] "
                : "text-[#FFFFFF] bg-[#6935D3] border-[0.77px] border-transparent"
            }`
      }
    >
      <h2> {loading ? <Loader /> : <h2>{title}</h2>}</h2>
    </div>
  );
};

export default Button;
