import React, { useEffect, useState } from "react";

import image1 from "../../Assets/Images/why1.png";
import image2 from "../../Assets/Images/why2.png";
import image3 from "../../Assets/Images/why3.png";
import image4 from "../../Assets/Images/why4.png";

const Item = ({ image, text }) => {
  return (
    <div className="flex flex-col bg-[#6935D3] justify-between  rounded-[18.67px] w-[300px] h-[220px] px-[30px] py-[30px] 2xl:w-[360px] 2xl:h-[250px] 3xl:w-[440px] 3xl:h-[330px] 2xl:px-[30px] 2xl:py-[40px] 4xl:w-[466.67px] 4xl:h-[369.33px] gap-3">
      <img
        src={image}
        className="w-[50px] h-[50px] 3xl:w-[70px] 3xl:h-[70px] 4xl:w-[88px] 4xl:h-[88px]"
      />
      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[40px] 4xl:text-[46px]">
        {text}
      </h2>
    </div>
  );
};
const speed = 15000;

const WhyInvest = () => {
  const [position, setPosition] = useState("70");
  function moveCircle() {
    const speed = 5;
    const scrolltop = window.pageYOffset;
    const scrollAndSpeed = scrolltop / speed;

    setPosition(scrollAndSpeed.toString());
  }

  useEffect(() => {
    window.addEventListener(
      "scroll",
      function () {
        requestAnimationFrame(moveCircle);
      },
      false
    );
  }, []);

  return (
    <div className="2xl:min-h-[70vh] flex justify-end items-center bg-[#FFF] py-[5%] w-full ">
      <div className="w-[98%] mx-auto overflow-hidden ">
        <div className=" px-[2.5%]">
          <h4 className=" font-[700] text-[#131E3D] mb-4 text-[40px] lg:text-[54px] 3xl:text-[60px] 4xl:text-[64px] leading-[44px] lg:leading-[56px] 3xl:leading-[68.86px] 4xl:leading-[72.86px] ">
            Why Invest with Halvest?
          </h4>

          <div className="relative">
            <div className="inner">
              <div className="wrapper flex overflow-hidden ">
                <section
                  className="flex section px-20 w-max gap-10 my-9 3xl:mt-12"
                  style={{ "--speed": `${speed}ms` }}
                >
                  <Item
                    image={image1}
                    text={"Global and Unique Opportunities"}
                  />

                  <Item
                    image={image2}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Alternative <br />
                        Investments
                      </h2>
                    }
                  />
                  <Item
                    image={image3}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Dollar <br />
                        Investments
                      </h2>
                    }
                  />
                  <Item
                    image={image4}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Diversified <br />
                        Portfolio
                      </h2>
                    }
                  />
                  <Item
                    image={image2}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Shari’ah <br />
                        Compliance
                      </h2>
                    }
                  />
                  <Item
                    image={image3}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Superior
                        <br />
                        Returns
                      </h2>
                    }
                  />
                </section>
              </div>
            </div>
            {/* <div className="absolute top-0 inner">
              <div className="wrapper flex overflow-hidden ">
                <section
                  className="flex section2 px-20 w-max gap-10 my-9 3xl:mt-12"
                  style={{ "--speed": `${speed}ms` }}
                >
                  <Item
                    image={image1}
                    text={"Global and Unique Opportunities"}
                  />

                  <Item
                    image={image2}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Alternative <br />
                        Investments
                      </h2>
                    }
                  />
                  <Item
                    image={image3}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Dollar <br />
                        Investments
                      </h2>
                    }
                  />
                  <Item
                    image={image4}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Diversified <br />
                        Portfolio
                      </h2>
                    }
                  />
                  <Item
                    image={image2}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Shari’ah <br />
                        Compliance
                      </h2>
                    }
                  />
                  <Item
                    image={image3}
                    text={
                      <h2 className="text-[#FFF]  font-[700]  w-full text-[28px] 2xl:text-[33px] leading-[34px] 2xl:leading-[36px] 3xl:leading-[57.6px] tracking-[-1%] 3xl:text-[44px] 4xl:text-[48px]">
                        Superior
                        <br />
                        Returns
                      </h2>
                    }
                  />
                </section>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyInvest;
