import React from "react";

const CloseIcon = (props) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="9.8891"
        y="10.5962"
        width="13.9853"
        height="1"
        transform="rotate(-135 9.8891 10.5962)"
        fill="#292929"
      />
      <rect
        y="9.88904"
        width="13.9853"
        height="1"
        transform="rotate(-45 0 9.88904)"
        fill="#292929"
      />
    </svg>
  );
};

export default CloseIcon;
