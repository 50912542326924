import React from "react";
import logo from "../../Assets/Logo/Logo.png";
import img from "../../Assets/Images/Logo-Footer.png";
import sec from "../../Assets/Images/sec.png";
import Button from "../Inputs/Button";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Client } from "../../Utils/client";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const { data } = await Client({
          method: "POST",
          path: "https://api.halvestco.com/api/v1/subscribe/add",
          data: {
            email: values?.email,
          },
          type: "full-path",
        });

        resetForm();
        toast.success(
          "You’re in! You’re now on the list to stay updated on our journey."
        );
      } catch (error) {
        resetForm();
        toast.error(
          error?.response?.data?.errors?.email[0] ||
            error?.response?.data?.message
        );
      }

      setSubmitting(false);
    },
  });

  return (
    <div className="  bg-[#FFF] pt-2  w-full ">
      <div className=" w-[90%] mb-[8%] mx-auto flex-col md:flex-row flex gap-10">
        <div className="h-[100%] gap-10 flex flex-col">
          <div>
            <img src={logo} className=" w-[200px]" />
            <h5 className="font-[400] mt-5 mb-6 max-w-[266.67px] text-[#757B8D]   text-[14px] 3xl:text-[14px] 4xl:text-[16px]  3xl:leading-[16.8px] 4xl:leading-[20.8px] ">
              Empowering Africans through Global Ethical Investments
            </h5>
            <img src={sec} className=" w-[260px]" />
          </div>
          <div>
            <h4 className=" font-[400] max-w-[288px] text-[#232323]  text-[20px] 3xl:text-[20px] 4xl:text-[24px] leading-[24px] 3xl:leading-[24.24px] 4xl:leading-[31px]">
              Sign up for our weekly newsletter to get updates
            </h4>
            <input
              type="email"
              onChange={form.handleChange}
              value={form.values?.email}
              id="email"
              placeholder="Your Email here"
              className="text-[16.84px] fontt-[400] mt-4 text-[#757B8D] rounded-[10.67px] 4xl:text-[21.33px] h-[54.5px] 4xl:h-[70.67px] w-[353px] border-[1.7px] border-[#ECECEC] bg-[#F8F8F8] outline-none p-[4%]"
            />
            <Button
              onClick={form.handleSubmit}
              loading={form.isSubmitting}
              classes={
                "mt-2 4xl:text-[21.12px] 4xl:mt-4 4xl:h-[77px] !w-[353px]"
              }
              title={"Subscribe"}
            />
          </div>
        </div>
        <div className="flex-1">
          <div className="w-full flex-wrap gap-y-4 pb-10 border-b-[#D9D9D9] border-b-[1.33px] flex justify-between">
            <div className="flex flex-col gap-3 min-w-[190.33px] 3xl:min-w-[238.33px]">
              <h3 className=" font-[500]  text-[#000000]  text-[22px] 3xl:text-[22px] 4xl:text-[26.67px]">
                Product
              </h3>
              <h4 className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                Business Financing
              </h4>
              <h4 className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                Real Estate
              </h4>
              <h4 className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                Venture Investing
              </h4>
            </div>
            <div className="flex flex-col gap-3 min-w-[190.33px] 3xl:min-w-[238.33px]">
              <h3 className=" font-[500]  text-[#000000]  text-[22px] 3xl:text-[22px] 4xl:text-[26.67px]">
                Company
              </h3>
              {/* <h4 className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                About Us
              </h4> */}
              <h4
                onClick={() => {
                  navigate("/privacy");
                }}
                // href="https://halvestco.com/Legal/Terms.docx.pdf"
                // target="_blank"
                className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]"
              >
                Privacy
              </h4>
              <h4
                onClick={() => {
                  navigate("/terms");
                }}
                // href="https://halvestco.com/Legal/Privacy%20Policy.docx.pdf"
                // target="_blank"
                className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]"
              >
                Terms
              </h4>
            </div>
            {/* <div className="flex flex-col gap-3 min-w-[190.33px] 3xl:min-w-[238.33px]">
              <h3 className=" font-[500]  text-[#000000]  text-[22px] 3xl:text-[22px] 4xl:text-[26.67px]">
                Resources
              </h3>

              <h4 className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                Membership
              </h4>
              <h4 className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                FAQs
              </h4>
            </div> */}
            <div className="flex flex-col gap-3 min-w-[190.33px] 3xl:min-w-[238.33px]">
              <h3 className=" font-[500]  text-[#000000]  text-[22px] 3xl:text-[22px] 4xl:text-[26.67px]">
                Contact
              </h3>
              <h4 className=" font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                +2347042915411
              </h4>
              <h4 className=" font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                +1 5197293470
              </h4>
              <h4 className=" font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                hello@halvestco.com
              </h4>
              {/* <h4
                onClick={() => {
                  navigate("/contact-us");
                }}
                className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]"
              >
                Contact Us
              </h4> */}
            </div>
          </div>
          <div className="w-full flex-wrap gap-y-4 py-10 border-b-[#D9D9D9] border-b-[1.33px] flex items-center justify-between">
            <div className="max-w-[300px] 3xl:max-w-[374.67px]">
              <h4 className=" font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                🇳🇬
                <br />
                12f Tola Adewunmi Street, Maryland Estate, Lagos Nigeria.
              </h4>
            </div>
            <div className="max-w-[300px] 3xl:max-w-[374.67px]">
              <h4 className=" font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                🇨🇦
                <br />2 King St W Suite 110, Kitchener, ON N2G 1A3
              </h4>
            </div>
            <div className="max-w-[300px] 3xl:max-w-[374.67px]">
              <h4 className=" font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                🇺🇸
                <br />
                1007 N Orange St. 4th Floor Ste 1382, Wilmington Delaware
              </h4>
            </div>
          </div>
          <div className="w-full flex-wrap gap-y-4 py-10 border-b-[#D9D9D9] border-b-[1.33px] flex items-center justify-between">
            <div className="max-w-[300px] gap-3 flex items-center 3xl:max-w-[374.67px]">
              <a
                href="https://www.youtube.com/@halvestco"
                target="_blank"
                className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]"
              >
                Youtube
              </a>
              <a
                href="https://www.linkedin.com/company/halvestco/posts/?feedView=all"
                target="_blank"
                className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]"
              >
                LinkedIn
              </a>
              <a
                href="https://www.instagram.com/halvestco/?igsh=MWx3N2dta2ZyendpZg%3D%3D#"
                target="_blank"
                className="transition duration-[500ms] ease-in-out hover:text-[#6935D3] cursor-pointer font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]"
              >
                Instagram
              </a>
            </div>
            <div className="max-w-[300px] 3xl:max-w-[374.67px]">
              <h4 className=" font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]">
                ©{new Date().getFullYear()} Halvest.All rights reserved.
              </h4>
            </div>
          </div>
          <div className="w-full flex-wrap gap-y-4 py-10 border-b-[#D9D9D9] border-b-[1.33px] flex items-center justify-between">
            <div className="w-[95%] md:max-w-[86%]">
              <h4 className=" font-[400] text-[#757B8D]  text-[14px] 3xl:text-[14px] 4xl:text-[16px]  3xl:leading-[16.8px] 4xl:leading-[20.8px] ">
                Halvest is duly registered in Nigeria, Delaware (USA), and
                Ontario (Canada). Operating under a money lender license and as
                a cooperative investment platform, we facilitate business
                financing and real estate projects for our members only. Banking
                services are provided through a licensed partner, and we hold an
                SCUML License to ensure compliance with the Money Laundering
                (Prohibition) Act, 2004.
              </h4>
              {/* <h4 className=" font-[400] text-[#757B8D]  text-[18px] 3xl:text-[18px] 4xl:text-[21.33px]"></h4> */}
              <h4 className=" font-[400] mt-2 text-[#757B8D]  text-[14px] 3xl:text-[14px] 4xl:text-[16px]  3xl:leading-[16.8px] 4xl:leading-[20.8px] ">
                Investments involve risk, including potential loss of capital.
                Past performance is not indicative of future results. By using
                Halvest, you agree to our Terms of Service and Privacy Policy.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full ">
        <img src={img} className="w-full" />
      </div>
    </div>
  );
};

export default Footer;
