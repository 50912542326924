import React from "react";

import show from "../../Assets/Images/showoff2.png";

const Show = () => {
  return (
    <div className="  bg-[#FFF] pt-2 pb-10 w-full ">
      <div className=" w-[90%] mx-auto ">
        <img src={show} className="w-full" />
      </div>
    </div>
  );
};

export default Show;
