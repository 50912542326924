import React, { useState } from "react";

import GS from "../../Assets/Images/GS.png";
import GS1 from "../../Assets/Images/GS1.png";
import GS2 from "../../Assets/Images/GS2.png";
import GS3 from "../../Assets/Images/GS3.png";
import GS4 from "../../Assets/Images/GS4.png";
import dot from "../../Assets/Images/dot-i.png";

import Button from "../Inputs/Button";

const Step = ({ image, title, sub }) => {
  return (
    <div className="w-[85%] max-w-[490px] pt-6 pb-4  border-b-[#D5D5D5] border-b ">
      <img
        src={image}
        className="w-[51px] h-[52.88px] 3xl:w-[61px] 3xl:h-[63.88px] "
      />
      <h4 className="mt-2 3xl:my-2 font-[500] text-[#131E3D]  text-[26px] 3xl:text-[38px] 4xl:text-[42px] leading-[32.94px] 3xl:leading-[40.94px] 4xl:leading-[44.94px] ">
        {title}
      </h4>
      <h5 className=" font-[400] text-[#6D6E73]  text-[14px] 3xl:text-[20px] 4xl:text-[24px] leading-[20.94px] 3xl:leading-[27.2px] 4xl:leading-[31.2px] ">
        {sub}
      </h5>
    </div>
  );
};
const GetStarted = () => {
  const [rangeValue, setRangeValue] = useState(300000);

  return (
    <div className="min-h-[80vh]  bg-[#FFF] pt-2 pb-28 w-full ">
      <div className=" w-full gap-[2%]">
        <h2 className="text-[#131E3D]  font-[700] mx-auto text-center max-w-[93%] md:w-full text-[40px] leading-[44px] mb-3 3xl:text-[48px] 3xl:leading-[52px]">
          Getting started is easy & sw
          <span className=" relative inline-block">
            i
            <img
              src={dot}
              alt="Leaf"
              className="absolute top-[16%] left-[-4] 3xl:top-[14.2%] 3xl:left-[-4] opacity-100 right-[0px] w-[29.52px] h-[14.58px] 3xl:w-[32.52px] 3xl:h-[18.58px]"
            />
          </span>
          ft.
        </h2>
        <h3 className=" font-[400] max-w-[93%] md:max-w-[500px] mx-auto 3xl:max-w-[679px] text-[#6D6E73] text-center max-[679px] text-[24px] 3xl:text-[28px] 4xl:text-[32px] leading-[33.6px] 3xl:leading-[37.6px] 4xl:leading-[41.6px] ">
          Join in minutes and take the first step toward your financial growth.
        </h3>

        <div className="max-w-[70%] mx-auto  ">
          <div className="w-full flex flex-col sm:flex-row  gap-10 ">
            <div className="flex-1  block sm:hidden  ">
              <div className="w-full px-[6%]">
                <img src={GS} className="w-full  mt-[-25%]" />
              </div>
            </div>
            <div className="flex-1 pt-10">
              <Step
                image={GS1}
                sub={"Your first step towards financial freedom."}
                title={"Create an account"}
              />
              <Step
                image={GS2}
                sub={"Seamlessly link your account to start investing."}
                title={"Add a payment method"}
              />
              <Step
                image={GS3}
                sub={"Unlock a world of profitable investments."}
                title={"Explore opportunities"}
              />
              <Step
                image={GS4}
                sub={"Put your money to work and build wealth, ethically"}
                title={"Invest & grow good"}
              />
              <Button
                onClick={() => {}}
                classes={
                  "mt-8 4xl:text-[21.12px] 4xl:mt-10 4xl:h-[77px] !w-[244px]"
                }
                link="https://app.halvestco.com/signin"
                title={"Start investing"}
              />
            </div>

            <div className="flex-1 hidden sm:block  ">
              <div className="w-full px-[6%]">
                <img src={GS} className="w-full  mt-[-25%]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
