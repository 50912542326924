import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Button from "../Inputs/Button";
import MenuIcon from "../../Assets/SVGS/MenuIcon";
import CloseIcon from "../../Assets/SVGS/CloseIcon";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import logo from "../../Assets/Logo/Logo.png";
const Menu = ({ setMenuOpen }) => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();

  const handleClickLink = (param) => {
    navigate(param);
    setMenuOpen(false);
  };

  const [isProduct, setIsProduct] = useState(false);
  const links = [
    // { id: 3, textLink: "About", link: "/about" },
    // { id: 4, textLink: "Products", link: "/" },
    // { id: 4, textLink: "Our Customers", link: "/" },
    // { id: 5, textLink: "Process", link: "/process" },
  ];

  const portfolioLinks = [
    {
      id: 1,

      textLink: (
        <div
          onClick={() => {
            setIsProduct(false);
          }}
          className="text-[#C6C0C0] flex items-center"
        >
          <CgChevronLeft />
          <h4>Back</h4>
        </div>
      ),
    },
    { id: 4, textLink: "Business Financing", link: "/" },
    { id: 5, textLink: "Real Estate", link: "/" },
    { id: 6, textLink: "Venture Investing", link: "/" },
  ];

  return (
    <div className="h-[80vh]">
      <div className="flex flex-col  items-center justify-center menu-height h-full lg:hidden ">
        <div className=" ">
          {/* <ul className="">
            {(isProduct ? portfolioLinks : links).map(
              ({ id, textLink, link, hasOthers }) => (
                <li
                  className={`text-[#192851] font-normal flex gap-1 justify-center items-center text-[24px] py-6 text-center ${
                    pathName == link && "active2"
                  }`}
                  key={id}
                  onClick={() => (link ? handleClickLink(link) : null)}
                >
                  <p>{textLink}</p>
                </li>
              )
            )}
          </ul> */}
          <Button
            link="https://app.halvestco.com/signin"
            onClick={() => {}}
            outLine
            title={"Login"}
            classes={"mb-10 min-w-[274.41px] mt-0"}
          />
          <Button
            link="https://app.halvestco.com/nationality"
            title={"Sign up"}
            onClick={() => {}}
            classes={"mb-3 min-w-[274.41px]"}
          />
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  const [selected, setSelected] = useState();
  return (
    <>
      <nav
        id="header"
        className="sticky w-[97%] lg:max-w-[90%] rounded-[18px] md:rounded-[20px] lg:flex lg:items-center lg:justify-center mx-auto lg:h-[95px]  z-50 top-3 bg-[#FFF] "
      >
        <div className="w-full px-8  sm:p-4  mx-auto flex items-center justify-between ">
          <div className=" py-[16px]  flex cursor-pointer gap-4 items-center">
            <a href="/" className=" pt-[2px] pb-1 px-2">
              <img src={logo} className=" w-[133.33px]" />
            </a>
            <div className="hidden lg:flex gap-1">
              {/* <div className="bg-[#F1EDFB] rounded-[24.19px]  px-7 flex justify-center items-center h-[32px]">
                <h4 className="font-[500] text-[14px] leading-[19.2px] text-[#131E3D]">
                  Personal
                </h4>
              </div> */}
              {/* <div
                className={`${
                  false ? "bg-[#F1EDFB]" : ""
                } transition duration-[500ms] ease-in-out hover:bg-[#F1EDFB]  rounded-[24.19px]  px-7 flex justify-center items-center h-[32px]`}
              >
                <h4
                  className={` ${
                    false ? "font-[500]" : "font-[450]"
                  } text-[14px] leading-[19.2px] text-[#131E3D]`}
                >
                  For Business
                </h4>
              </div> */}
            </div>
          </div>

          <div className="hidden lg:flex gap-5">
            {/* <div
              className={`${
                false ? "" : ""
              } transition duration-[800ms]  cursor-pointer ease-in-out border-b border-b-transparent hover:border-b-[#6935D3]  flex justify-center items-center h-[28px]`}
            >
              <h4
                className={` ${
                  false ? "" : ""
                }  font-[500] text-[16px]  text-[#131E3D]`}
              >
                About
              </h4>
            </div>
            <div
              className={`${
                false ? "" : ""
              } transition duration-[800ms]  cursor-pointer ease-in-out border-b border-b-transparent  hover:border-b-[#6935D3] flex justify-center items-center h-[28px]`}
            >
              <h4
                className={` ${
                  false ? "" : ""
                }  font-[500] text-[16px]  text-[#131E3D]`}
              >
                Products
              </h4>
            </div>
            <div
              className={`${
                false ? "" : ""
              } transition duration-[800ms] cursor-pointer ease-in-out border-b border-b-transparent hover:border-b-[#6935D3]  flex justify-center items-center h-[28px]`}
            >
              <h4
                className={` ${
                  false ? "" : ""
                }  font-[500] text-[16px]  text-[#131E3D]`}
              >
                Our Customers
              </h4>
            </div> */}
          </div>
          <div className="hidden px-2  lg:flex gap-4">
            <Button
              link="https://app.halvestco.com/signin"
              onClick={() => {}}
              outLine
              title={"Login"}
            />
            <Button
              link="https://app.halvestco.com/nationality"
              title={"Sign up"}
              onClick={() => {}}
            />
          </div>

          <div className="block py-[16px] ml-3 lg:hidden pr-2">
            <button
              id="nav-toggle"
              className="flex items-center p-1 text-[#000] focus:outline-none focus:shadow-outlinet"
            >
              {!menuOpen ? (
                <MenuIcon onClick={() => setMenuOpen(!menuOpen)} />
              ) : (
                <CloseIcon onClick={() => setMenuOpen(!menuOpen)} />
              )}
            </button>
          </div>
        </div>
      </nav>
      {menuOpen && (
        <div
          className={`fixed mt-[0px] w-full h-full z-50 bg-white ${
            menuOpen ? "menu-wrapper " : " menu-hidden"
          }`}
        >
          <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
      )}
    </>
  );
};

export default Header;
