import React from "react";

const MenuIcon = (props) => {
  return (
    <svg
      width="48"
      height="38"
      viewBox="0 0 48 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="6.60999" y="13.42" width="35" height="1" fill="#292929" />
      <rect x="6.60999" y="24.42" width="35" height="1" fill="#292929" />
    </svg>
  );
};

export default MenuIcon;
