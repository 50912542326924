import React from "react";
import image from "../../Assets/Images/your-money-is-safe.png";
import image1 from "../../Assets/Images/MS1.png";
import image2 from "../../Assets/Images/MS2.png";
import image3 from "../../Assets/Images/MS3.png";
import image4 from "../../Assets/Images/MS4.png";

const Item = ({ image, text1, text2 }) => {
  return (
    <div className="flex gap-3 ">
      <img
        src={image}
        className="w-[38.88px] h-[40.57px] 4xl:w-[48.88px] 4xl:h-[50.57px]"
      />
      <div className="w-[310px] 3xl:w-[428px]">
        <h3 className="font-[700] text-[22px] text-[#FFF] 3xl:text-[28px] 4xl:text-[32px] leading-[28.4px] mb-1 3xl:leading-[34.4px] 4xl:leading-[38.4px]">
          {text1}
        </h3>
        <h3 className="font-[300] text-[#FFF] text-[14px] 3xl:text-[16px] 4xl:text-[20px]">
          {text2}
        </h3>
      </div>
    </div>
  );
};

const MoneyIsSafe = () => {
  return (
    <div className="2xl:h-[90vh] flex justify-between items-center bg-[#131E3D] py-10 w-full ">
      <div className="max-w-[90%] z-[30] mx-auto">
        <div className="max-w-[75%] ">
          <h4 className=" font-[700] max-w-[498px] 3xl:max-w-[598px] text-[#FFF] text-[48px] leading-[50px]  lg:text-[54px] 3xl:text-[60px] 4xl:text-[64px] lg:leading-[56px] 3xl:leading-[68.86px] 4xl:leading-[72.86px] ">
            Your money is safe with Halvest.
          </h4>
          <h3 className=" font-[400] mt-3 4xl:mt-6 max-w-[498px] 3xl:max-w-[598px] text-[#FFF]  max-[598px] text-[24px] 3xl:text-[28px] 4xl:text-[32px] leading-[33.6px] 3xl:leading-[37.6px] 4xl:leading-[41.6px] ">
            We do not only keep your money free from interest, we keep it safe.
          </h3>
          <div className=" flex flex-wrap gap-y-10 gap-x-12 3xl:gap-y-14 3xl:gap-x-16 mt-7 3xl:mt-12">
            <Item
              image={image1}
              text1={"Safety & Security"}
              text2={
                "At Halvest, your peace of mind is our priority. With advanced encryption and robust security, your personal and financial information stays protected at every step."
              }
            />
            <Item
              image={image2}
              text1={"⁠⁠Regulatory Compliance"}
              text2={
                "We operate under a cooperative and money lender’s license, ensuring legal compliance with our current business operations. We are working to secure additional licenses as we broaden our investment products."
              }
            />
            <Item
              image={image4}
              text1={"Reliable Partners"}
              text2={
                "We collaborate with trusted partners who share our commitment to excellence and ethical values. Our network is carefully vetted to ensure you invest in credit worthy businesses."
              }
            />
            <Item
              image={image3}
              text1={"Credible Investments"}
              text2={
                "Every opportunity on Halvest is rigorously screened to ensure it meets our ethical and performance criteria. We provide Shariah-compliant, high-impact investment options, empowering you to grow your wealth responsibly while making a positive difference."
              }
            />
          </div>
        </div>
      </div>
      <div className="h-full  absolute right-0 flex items-center justify-center">
        <img src={image} className="h-[80%] object-cover" />
      </div>
    </div>
  );
};

export default MoneyIsSafe;
