import React, { useEffect } from "react";
import SEO from "../Components/Seo";
import Footer from "../Components/Nav/Footer";
import Header from "../Components/Nav/Header";
import Show from "../Components/Home/Show";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="bg-[#F5F5F5] pt-6">
      <SEO title={""} description={""} name={"Halves Terms"} type={"article"} />
      {/* <Header />
      <div className="min-h-[80vh] lg:max-h-[40vh] lg:mb-[-7%]  overflow-hidden pt-4 pb-28 justify-center items-center flex w-full m-auto max-w-[90%]">
        <div className="flex w-full flex-col justify-center items-center lg:flex-row gap-[20px] lg:gap-[2%]">
          <div className="flex-1 items-center mt-10 w-full md:items-start flex flex-col justify-center gap-5">
            <h2 className="text-[48px] leading-[50px]  lg:text-[54px] 3xl:text-[60px] 4xl:text-[64px] lg:leading-[56px] 3xl:leading-[68.86px] 4xl:leading-[72.86px] font-[700] mb-1 mt-2 text-[#131E3D]  text-center sm:text-left ">
              Terms & Conditions
            </h2>

            <h4 className="text-[20px] 3xl:text-[24px] leading-[24.94px] 3xl:leading-[31.2px] font-[400] mb-5 max-w-[75%] text-[#6D6E73] text-center sm:text-left ">
              Last updated: <span className="font-[700]">Feb 26, 2024</span>
            </h4>
          </div>

          <div className="flex-1 min-h-[40vh] lg:min-h-[75vh]  h-auto flex gap-4">
            <div className="flex w-full relative justify-end flex-col">
              <img src="/Assets/Terms.png" className="w-full" />
            </div>
          </div>
        </div>
      </div>

      <Show />
      <Footer /> */}
    </div>
  );
};

export default Contact;
