import React, { useState } from "react";

import image from "../../Assets/Images/Yusuf.jpg";
import Funmi from "../../Assets/Images/Funmi.jpg";
import Last from "../../Assets/Images/Last.png";
import image2 from "../../Assets/Images/hear7.png";
import next from "../../Assets/Images/next.png";
import back from "../../Assets/Images/back.png";
import PlayBtn from "../../Assets/Images/PlayBtn.png";
import Button from "../Inputs/Button";

const Hear = () => {
  const [current, setCurrent] = useState(0);

  const [showVideo, setShowVideo] = useState(false);

  const list = [
    {
      img: image,
      comment:
        "Investing with Halvest has been good so far.  I no longer have to worry about solely depending on my day job.",
      name: "AbdulRahman Yusuf",
      from: "Halvest member since 2021",
      video:
        "https://www.youtube.com/embed/DM9M7DrJ1lY?si=vONuKvi35kVP9YuL&amp;controls=0",
    },
    {
      img: Funmi,
      comment:
        "I can set my financial goals, know how much to invest, and earn on a monthly basis, which has helped me create additional income for myself and my family.",
      name: "Funmi Abdulrahman",
      from: "Halvest Member since 2021",
      video:
        "https://www.youtube.com/embed/j2GHiUfwqWo?si=kfGgKcVCFV1nW76t&amp;controls=0",
    },
    {
      img: Last,
      comment:
        "Investing with Halvest has given me an option to manage my idle funds by putting them to work and earning returns on them until the time I require those funds.",
      name: "Hammed Taiwo",
      from: "Halvest member since 2022",
      video:
        "https://www.youtube.com/embed/3ibHVTRBCTw?si=XOniqoukRWN_QX9Y&amp;controls=0",
    },
  ];
  return (
    <>
      <div className="min-h-[80vh]  bg-[#FFF] pt-2 pb-28 w-full ">
        <div className=" w-full gap-[2%]">
          <h2 className="text-[#131E3D] max-w-[93%] md:max-w-[620px] mx-auto 3xl:max-w-[763px]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] text-center w-full text-[40px] 3xl:text-[48px]">
            Hear From Halvestors
          </h2>
          <h3 className=" font-[400]  mt-3 max-w-[93%] md:max-w-[500px] mx-auto 3xl:max-w-[679px] text-[#6D6E73] text-center max-[679px] text-[24px] 3xl:text-[36px] 4xl:text-[40px] leading-[33.6px] 3xl:leading-[45.6px] 4xl:leading-[52px] ">
            Insights and success stories from those who’ve walked the path
          </h3>

          <div className="max-w-[90%] mt-16 mx-auto  ">
            <div className="w-full flex flex-wrap gap-10 mb-6 ">
              <div className="w-full md:max-w-[45%]  overflow-hidden  ">
                <div className="w-full relative ">
                  <img
                    src={list[current].img}
                    className="w-full object-cover h-[400px] 3xl:h-[550px] rounded-[20.08px] lg:rounded-[35.08px] md:w-[690px] "
                  />
                  <img
                    onClick={() => {
                      setShowVideo(true);
                    }}
                    src={PlayBtn}
                    className="w-[61.2px] cursor-pointer object-cover absolute bottom-8 left-10 h-[61.2px] 3xl:w-[81.2px] 3xl:h-[81.2px]"
                  />
                </div>
              </div>
              <div className="flex-1 flex-col flex justify-between h-[400px] 3xl:h-[550px] 35.08px bg-[#F9F9F9] py-[4%] rounded-[20.08px] lg:rounded-[35.08px] px-[28px] md:px-[3%]">
                <div>
                  <h4 className="mt-2  font-[700] text-[#131E3D]  text-[24px] 3xl:text-[32.78px] 4xl:text-[36.78px]  ">
                    Halves<span className="font-[300]">timonials</span>
                  </h4>
                  <h5 className=" font-[400] mt-5 w-[85%]  text-[#6D6E73]  text-[14px] lg:text-[30px] 3xl:text-[30px] 4xl:text-[34px] lg:leading-[40px] 3xl:leading-[40px] 4xl:leading-[52px] ">
                    {list[current].comment}
                  </h5>
                </div>
                <div className="flex justify-between items-end">
                  <div className="">
                    <h5 className=" font-[500] text-[#757B8D] text-[20px] 3xl:text-[25px] 4xl:text-[29.54px] ">
                      {list[current].name}
                    </h5>
                    <h3 className="mt-2font-[400] text-[#757B8D]   text-[14.15px] 3xl:text-[18.15px] 4xl:text-[22.15px] ">
                      {list[current].from}
                    </h3>
                  </div>

                  <img
                    src={image2}
                    className="transition duration-[500ms] ease-in-out hover:scale-[95%] w-[20px] h[23px] md:w-[50px] md:h-[59px] 4xl:w-[80.18px] 4xl:h-[89.79px]"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col md:hidden items-center gap-2 justify-between">
              <div className="flex gap-3 lg:gap-6 ">
                <img
                  onClick={() => {
                    if (current === 0) {
                      setCurrent(list?.length - 1);
                    } else {
                      setCurrent(current - 1);
                    }
                  }}
                  src={back}
                  className="transition duration-[500ms] ease-in-out hover:scale-[95%] cursor-pointer w-[56px] h-[56px] 4xl:w-[77px] 4xl:h-[77px]"
                />
                <img
                  onClick={() => {
                    if (current === list?.length - 1) {
                      setCurrent(0);
                    } else {
                      setCurrent(current + 1);
                    }
                  }}
                  src={next}
                  className="transition duration-[500ms] ease-in-out hover:scale-[95%] cursor-pointer w-[56px] h-[56px] 4xl:w-[77px] 4xl:h-[77px]"
                />
              </div>
              <Button
                onClick={() => {}}
                classes={
                  "mt-6 4xl:text-[21.12px] 4xl:mt-8 4xl:h-[77px] !w-[244px]"
                }
                link="https://app.halvestco.com/nationality"
                title={"Start investing"}
              />
            </div>
            <div className="hidden  md:flex items-center justify-between">
              <Button
                onClick={() => {}}
                classes={" 4xl:text-[21.12px] 4xl:mt-2 4xl:h-[77px] !w-[244px]"}
                link="https://app.halvestco.com/nationality"
                title={"Start investing"}
              />
              <div className="flex gap-3 lg:gap-6 ">
                <img
                  onClick={() => {
                    if (current === 0) {
                      setCurrent(list?.length - 1);
                    } else {
                      setCurrent(current - 1);
                    }
                  }}
                  src={back}
                  className="transition duration-[500ms] ease-in-out hover:scale-[95%] cursor-pointer w-[56px] h-[56px] 4xl:w-[77px] 4xl:h-[77px]"
                />
                <img
                  onClick={() => {
                    if (current === list?.length - 1) {
                      setCurrent(0);
                    } else {
                      setCurrent(current + 1);
                    }
                  }}
                  src={next}
                  className="transition duration-[500ms] ease-in-out hover:scale-[95%] cursor-pointer w-[56px] h-[56px] 4xl:w-[77px] 4xl:h-[77px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showVideo ? (
        <div className="w-[100vw] h-[100vh] fixed z-[100] top-0 left-0 bg-[rgba(0,0,0,0.3)] flex justify-center items-center">
          <div
            onClick={() => {
              setShowVideo(false);
            }}
            className="w-[100vw] h-[100vh] fixed z-[100] cursor-pointer top-0 left-0  flex justify-center items-center"
          ></div>

          <iframe
            src={list[current].video}
            title="YouTube video player"
            frameborder="0"
            au
            className="h-[90%] w-[90%] z-[100]"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen={true}
          ></iframe>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Hear;
