import React from "react";
import Fast from "../../Assets/Icons/fast.png";
import play from "../../Assets/Images/playstore.png";
import apple from "../../Assets/Images/apple.png";
import dot from "../../Assets/Images/dot-i.png";
const Hero = () => {
  return (
    <div className="min-h-[80vh] pt-4 pb-28 w-full m-auto max-w-[90%]">
      <div className="flex w-full flex-col lg:flex-row gap-[20px] lg:gap-[2%]">
        <div className="flex-1 items-center w-full md:items-start flex flex-col justify-center gap-5">
          <a
            href={"https://halvest-co-2.kit.com/halvest2024annualreport"}
            download={`Halvest Annual Report Document`}
            target="_blank"
          >
            <div className="flex font-[450] text-[11px] sm:text-[15.18px]  bg-[rgba(49,211,198,0.11)] gap-2  w-[330.26px] sm:w-[459.26px]  min-h-[44.83px] justify-center items-center rounded-[95.61px] border-[#31D3C6] border-[0.76px]  ">
              <h4>Our 2024 Annual Report is here! Download your copy</h4>
              <img src={Fast} className=" w-[12.9px] h-[15.93px]" />
            </div>
          </a>
          <h2 className="font-[700] mb-3 mt-2 text-[#131E3D] text-[44px] leading-[48px] md:text-[54px] md:leading-[56px] text-center sm:text-left lg:text-[68px]  lg:leading-[74px] 3xl:leading-[84px] 3xl:text-[82px]">
            Invest and <br />
            grow{" "}
            <span className="text-[#6935D3] relative inline-block">
              good
              <img
                src={dot}
                alt="Leaf"
                className="absolute top-[6px] right-[-5px] w-[27px] h-[17px]"
              />
            </span>
          </h2>
          {/* <h2 className="font-[700] text-[#131E3D] text-[44px] leading-[48px] md:text-[50px] md:leading-[54px] text-center sm:text-left lg:text-[58px]  lg:leading-[70px] 3xl:leading-[80px] 3xl:text-[68px]">
            Building wealth <br />
            through global <br />
            eth
            <span className="relative inline-block">
              i
              <img
                src={dot}
                alt="Leaf"
                className="absolute top-[3%] sm:top-[11%] left-[-4] 3xl:top-[12%] 3xl:left-[-4] opacity-100 right-[0px] w-[29.52px] h-[18.58px] 3xl:w-[32.52px] 3xl:h-[20.58px]"
              />
            </span>
            cal investments
          </h2> */}
          {/* <h4 className="font-[450] mb-3 max-w-[75%] text-[#6D6E73] text-[16px] text-center sm:text-left lg:text-[28px]  lg:leading-[35.6px] 3xl:leading-[41.6px] 3xl:text-[32px]">
            Seize opportunities, maximize returns, invest smartly without
            compromising your values or faith
          </h4> */}
          <h4 className="font-[450] mb-5 max-w-[75%] text-[#6D6E73] text-[16px] text-center sm:text-left lg:text-[28px]  lg:leading-[35.6px] 3xl:leading-[41.6px] 3xl:text-[32px]">
            Put your money to work and build wealth, ethically
          </h4>
          <div className="w-[346px] h-[70px] rounded-[10px] bg-[#6935D3] flex justify-center gap-4 items-center px-2">
            <div>
              <h4 className="text-[#FFF] font-[500] text-[22.67px]  tracking-[-1%]">
                Download via
              </h4>
            </div>

            <div className="flex items-center justify-center gap-2">
              <div
                className={` group relative    transition-all duration-[800ms] ease-in-out `}
              >
                <img src={play} className="w-[47px] h-[47px] cursor-pointer" />

                <div className="absolute overflow-hidden rounded-[10px] transition-all ease-in-out duration-[500ms]  flex flex-col gap-1 justify-center items-center top-[52px] -right-[50px] w-[150px]  bg-[#FFF] border group-hover:opacity-[100] opacity-0 ">
                  <div className="w-full hover:bg-[#F2F2F7] py-[10px] flex justify-center items-center">
                    <p
                      className={` transition-all ease-in-out duration-[300ms] text-[#6935D3] justify-center items-center text-[14px] font-[500] tracking-wide     text-center      `}
                    >
                      Coming Soon!
                    </p>
                  </div>
                </div>
              </div>
              <div className="h-[11px] w-[0.6px] bg-[#FFFFFF] "></div>

              <div
                className={` group relative    transition-all duration-[800ms] ease-in-out `}
              >
                <img src={apple} className="w-[47px] h-[47px] cursor-pointer" />

                <div className="absolute overflow-hidden rounded-[10px] transition-all ease-in-out duration-[500ms]  flex flex-col gap-1 justify-center items-center top-[52px] -right-[50px] w-[150px]  bg-[#FFF] border group-hover:opacity-[100] opacity-0 ">
                  <div className="w-full hover:bg-[#F2F2F7] py-[10px] flex justify-center items-center">
                    <p
                      className={` transition-all ease-in-out duration-[300ms] text-[#6935D3] justify-center items-center text-[14px] font-[500] tracking-wide     text-center      `}
                    >
                      Coming Soon!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 min-h-[40vh] lg:min-h-[75vh] h-auto flex gap-4">
          <div className="flex w-full relative justify-end flex-col">
            <img
              src="/Assets/hero5.png"
              className=" absolute w-[55%] bottom-[-20%] left-[5%] lg:left-[-20%] lg:bottom-[-10%] "
            />
            <img src="/Assets/hero2.png" className="w-full" />
          </div>
          <div className="relative w-full">
            <div className="w-full relative flex justify-between items-center">
              <img
                src="/Assets/hero3.png"
                className=" absolute w-[60%] max-w-[288px] left-[-42%] 3xl:left-[-188px] top-[20%] "
              />
              <img src="/Assets/hero1.png" className="w-full " />
            </div>
            <img
              src="/Assets/hero4.png"
              className="absolute w-[55%] bottom-[10px] left-[35px] "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
