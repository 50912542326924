import React from "react";

import Button from "../Inputs/Button";
import { useState, useEffect } from "react";
import { motion, useScroll } from "motion/react";
import { useRef } from "react";
const ExploreItem = ({ title, sub, item1, item2, item3, item4, item5 }) => {
  return (
    <motion.div
      initial={false}
      animate={{ opacity: 0.7 }}
      whileInView={{ opacity: 1 }}
      viewport={{ amount: 0.9 }}
      className="w-full max-w-[600px]  flex justify-center items-center pt-3 pb-3   gap-10 "
    >
      <div className=" min-w-[35%] max-w-[479px] pr-4">
        <h4 className="mt-2 3xl:my-2 font-[500] text-[#131E3D]  text-[26px] 3xl:text-[38px] 4xl:text-[42px] leading-[32.94px] 3xl:leading-[40.94px] 4xl:leading-[44.94px] ">
          {title}
        </h4>
        <h5 className=" font-[400] mt-4 text-[#6D6E73]  text-[14px] 3xl:text-[16px] 4xl:text-[20px] leading-[16.94px] 3xl:leading-[18.2px] 4xl:leading-[24.2px] ">
          {sub}
        </h5>
        <h5 className=" font-[500] mt-6 text-[#131E3D]  tracking-[-1] text-[14px] 3xl:text-[20px] 4xl:text-[24px] leading-[20.94px] 3xl:leading-[27.2px] 4xl:leading-[31.2px] ">
          What do I get?
        </h5>
        <div className="mt-4 flex gap-[8px] ">
          <div className="flex mt-[6px] flex-col items-center">
            <div className="bg-[#F1EDFB] rounded-full w-[10px] h-[10px] "></div>
            <div className="h-[40px] w-[1px] bg-[#F1EDFB] "></div>
            <div className="bg-[#F1EDFB] rounded-full w-[10px] h-[10px] "></div>
            <div className="h-[40px] w-[1px] bg-[#F1EDFB] "></div>
            <div className="bg-[#F1EDFB] rounded-full w-[10px] h-[10px] "></div>
            <div className="h-[40px] w-[1px] bg-[#F1EDFB] "></div>
            <div className="bg-[#F1EDFB] rounded-full w-[10px] h-[10px] "></div>
            <div className="h-[40px] w-[1px] bg-[#F1EDFB] "></div>
            <div className="bg-[#F1EDFB] rounded-full w-[10px] h-[10px] "></div>
          </div>
          <div>
            <h5 className="h-[50px] font-[450] text-[#131E3D]  tracking-[-1] text-[15px] 3xl:text-[16px] 4xl:text-[16px]  3xl:leading-[17.8px] 4xl:leading-[20.8px] ">
              {item1}
            </h5>
            <h5 className="h-[50px] font-[450] text-[#131E3D]  tracking-[-1] text-[15px] 3xl:text-[16px] 4xl:text-[16px]  3xl:leading-[17.8px] 4xl:leading-[20.8px] ">
              {item2}
            </h5>
            <h5 className="h-[50px] font-[450] text-[#131E3D]  tracking-[-1] text-[15px] 3xl:text-[16px] 4xl:text-[16px]  3xl:leading-[17.8px] 4xl:leading-[20.8px] ">
              {item3}
            </h5>
            <h5 className="h-[50px] font-[450] text-[#131E3D]  tracking-[-1] text-[15px] 3xl:text-[16px] 4xl:text-[16px]  3xl:leading-[17.8px] 4xl:leading-[20.8px] ">
              {item4}
            </h5>
            <h5 className=" h-[50px] font-[450] text-[#131E3D]  tracking-[-1] text-[15px] 3xl:text-[16px] 4xl:text-[16px]  3xl:leading-[17.8px] 4xl:leading-[20.8px] ">
              {item5}
            </h5>
          </div>
        </div>
        <Button
          onClick={() => {}}
          link="https://app.halvestco.com/signin"
          classes={"mt-4 4xl:text-[21.12px] 4xl:mt-6 4xl:h-[77px] !w-[244px] "}
          title={
            // title === "Venture Investing" ? "Coming Soon" : "Start investing"
            "Start investing"
          }
        />
      </div>
    </motion.div>
  );
};

export default ExploreItem;
