import React, { useRef } from "react";
import dot from "../../Assets/Images/dot-i.png";

import ExploreItem from "./ExploreItem";

import explore2 from "../../Assets/Images/vi.png";
import explore from "../../Assets/Images/rs.png";
import explore3 from "../../Assets/Images/bf.png";

import { AnimatePresence, motion, useScroll } from "motion/react";
import { useIsVisible } from "../../Utils/useIsVisible";

const Explore = () => {
  const boxRef = useRef(null);
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);

  const isVisible1 = useIsVisible(boxRef1);
  const isVisible2 = useIsVisible(boxRef2);
  const isVisible3 = useIsVisible(boxRef3);

  const { scrollYProgress } = useScroll({
    target: boxRef,
    offset: ["end end", "start start"],
  });

  return (
    <div className="min-h-[80vh]  bg-[#FFF] pt-2 pb-28 w-full ">
      <div className=" w-full gap-[2%]">
        <h2 className="text-[#131E3D] max-w-[93%] md:max-w-[620px] mx-auto 3xl:max-w-[763px]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] text-center w-full  text-[40px] 3xl:text-[48px]">
          Explore Diverse <br />
          Investment Opportunit
          <span className=" relative inline-block">
            i
            <img
              src={dot}
              alt="Leaf"
              className="absolute top-[4%] left-[-4] 3xl:top-[-3%] 4xl:top-[7%] 3xl:left-[-4] opacity-100 right-[0px] w-[29.52px] h-[14.58px] 3xl:w-[32.52px] 3xl:h-[18.58px]"
            />
          </span>
          es
        </h2>
        <h3 className=" font-[400] mt-3 max-w-[93%] md:max-w-[700px] mx-auto 3xl:max-w-[679px] text-[#6D6E73] text-center max-[679px] text-[24px] 3xl:text-[28px] 4xl:text-[32px] leading-[33.6px] 3xl:leading-[37.6px] 4xl:leading-[41.6px] ">
          From business financing to real estate, to venture investing, discover
          the perfect fit for your financial goals.
        </h3>

        <div className="max-w-[90%] mt-16 mx-auto  ">
          <div
            // initial={{ backgroundColor: "rgb(0, 255, 0)", opacity: 0 }}
            // whileInView={{ backgroundColor: "rgb(255, 0, 0)", opacity: 1 }}
            // viewport={{ amount: "all" }}
            className="w-full items-center md:items-start flex justify-center mb-2 gap-10 "
          >
            <div
              ref={boxRef}
              className="w-full flex 
               justify-center  gap-0 "
            >
              <div ref={boxRef} className="hidden lg:block ">
                {/* <AnimatePresence> */}
                {isVisible1 ? (
                  <motion.div
                    key="box"
                    // exit={{ opacity: 0 }}
                    className="w-[600px] h-[600px] md:sticky top-[20vh]  "
                  >
                    <div className="w-full px-[6%]">
                      <img src={explore3} className="w-[805.45px]  " />
                    </div>
                  </motion.div>
                ) : isVisible2 ? (
                  <motion.div
                    key="box2"
                    // exit={{ opacity: 0 }}
                    className="w-[600px] h-[600px] md:sticky top-[20vh]  "
                  >
                    <div className="w-full px-[6%]">
                      <img src={explore} className="w-[805.45px]  " />
                    </div>
                  </motion.div>
                ) : isVisible3 ? (
                  <motion.div
                    key="box4"
                    // exit={{ opacity: 0 }}
                    // transition={{
                    //   duration: 0.4,

                    //   ease: "easeInOut",
                    // }}
                    className="w-[600px] h-[600px] md:sticky top-[20vh]  "
                  >
                    <div className="w-full px-[6%]">
                      <img src={explore2} className="w-[805.45px]  " />
                    </div>
                  </motion.div>
                ) : null}
              </div>
              {/* </AnimatePresence> */}
              <div className="flex flex-col gap-14 lg:gap-32">
                <div className="w-full lg:hidden px-[6%]">
                  <img src={explore3} className="w-[805.45px]  " />
                </div>
                <div ref={boxRef1} className="">
                  <ExploreItem
                    title={"Business Financing"}
                    sub={
                      "Earn passive monthly income by investing in our rewarding business financing projects, focused on funding inventory and assets for businesses with sustainable models."
                    }
                    item1={"Short-term campaigns of 3 - 9 months"}
                    item2={
                      "Additional monthly income to cover personal expenses"
                    }
                    item3={"Ethical businesses with sustainable business model"}
                    item4={"Transparent investment process"}
                    item5={"Superior returns without interest "}
                  />
                </div>
                <div className="w-full lg:hidden px-[6%]">
                  <img src={explore} className="w-[805.45px]  " />
                </div>
                <div ref={boxRef2} className="lg:my-[5vh] ">
                  <ExploreItem
                    title={"Real Estate Investing"}
                    sub={
                      "Co-invest with fellow investors in our real estate projects in Dubai and the USA and receive monthly or quarterly rental income, all in dollars, without any interest."
                    }
                    item1={
                      "Dollar campaigns to hedge against currency devaluation"
                    }
                    item2={"Steady Rental Income "}
                    item3={"Hassle-free property management "}
                    item4={"Properties in high-demand locations "}
                    item5={"Flexible exit options for emergencies "}
                  />
                </div>
                <div className="w-full lg:hidden px-[6%]">
                  <img src={explore2} className="w-[805.45px]  " />
                </div>
                <div ref={boxRef3} className="pb-16 mb-0 ">
                  <ExploreItem
                    title={"Venture Investing"}
                    sub={
                      "Join other angel investors to own equity in sustainable businesses using our ESG focused investing model."
                    }
                    item1={"Equity ownership in high-growth companies  "}
                    item2={"Long-term wealth creation "}
                    item3={"Exclusive opportunities"}
                    item4={
                      "Diversified portfolio across multiple countries and industries"
                    }
                    item5={"High-growth potential"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;

// const Explore = () => {
//   const boxRef = useRef(null);
//   const boxRef1 = useRef(null);
//   const boxRef2 = useRef(null);
//   const boxRef3 = useRef(null);

//   const isVisible1 = useIsVisible(boxRef1);
//   const isVisible2 = useIsVisible(boxRef2);
//   const isVisible3 = useIsVisible(boxRef3);

//   console.log(isVisible1, isVisible2, isVisible3);

//   const { scrollYProgress } = useScroll({
//     target: boxRef,
//     offset: ["end end", "start start"],
//   });

//   return (
//     <div className="min-h-[80vh]  bg-[#FFF] pt-2 pb-28 w-full ">
//       <div className=" w-full gap-[2%]">
//         <h2 className="text-[#131E3D] max-w-[93%] md:max-w-[620px] mx-auto 3xl:max-w-[763px]  leading-[44.86px] 3xl:leading:-[54.86px] 4xl:leading-[58.86px] font-[700] text-center w-full  text-[40px] 3xl:text-[48px]">
//           Explore Diverse <br />
//           Investment Opportunit
//           <span className=" relative inline-block">
//             i
//             <img
//               src={dot}
//               alt="Leaf"
//               className="absolute top-[4%] left-[-4] 3xl:top-[-3%] 4xl:top-[7%] 3xl:left-[-4] opacity-100 right-[0px] w-[29.52px] h-[14.58px] 3xl:w-[32.52px] 3xl:h-[18.58px]"
//             />
//           </span>
//           es
//         </h2>
//         <h3 className=" font-[400] mt-3 max-w-[93%] md:max-w-[700px] mx-auto 3xl:max-w-[679px] text-[#6D6E73] text-center max-[679px] text-[24px] 3xl:text-[28px] 4xl:text-[32px] leading-[33.6px] 3xl:leading-[37.6px] 4xl:leading-[41.6px] ">
//           From business financing to real estate, to venture investing, discover
//           the perfect fit for your financial goals.
//         </h3>

//         <div className="max-w-[90%] mt-16 mx-auto  ">
//           <div
//             // initial={{ backgroundColor: "rgb(0, 255, 0)", opacity: 0 }}
//             // whileInView={{ backgroundColor: "rgb(255, 0, 0)", opacity: 1 }}
//             // viewport={{ amount: "all" }}
//             className="w-full items-center md:items-start flex justify-center mb-2 gap-10 "
//           >
//             <div
//               ref={boxRef}
//               className="w-full flex
//                justify-center  gap-0 "
//             >
//               <div ref={boxRef} className="hidden lg:block ">
//                 {/* <AnimatePresence> */}
//                 {isVisible1 ? (
//                   <motion.div
//                     key="box"
//                     // exit={{ opacity: 0 }}
//                     className="w-[600px] h-[600px] md:sticky top-[20vh]  "
//                   >
//                     <div className="w-full px-[6%]">
//                       <img src={explore3} className="w-[805.45px]  " />
//                     </div>
//                   </motion.div>
//                 ) : isVisible2 ? (
//                   <motion.div
//                     key="box2"
//                     // exit={{ opacity: 0 }}
//                     className="w-[600px] h-[600px] md:sticky top-[20vh]  "
//                   >
//                     <div className="w-full px-[6%]">
//                       <img src={explore} className="w-[805.45px]  " />
//                     </div>
//                   </motion.div>
//                 ) : isVisible3 ? (
//                   <motion.div
//                     key="box4"
//                     // exit={{ opacity: 0 }}
//                     // transition={{
//                     //   duration: 0.4,

//                     //   ease: "easeInOut",
//                     // }}
//                     className="w-[600px] h-[600px] md:sticky top-[20vh]  "
//                   >
//                     <div className="w-full px-[6%]">
//                       <img src={explore2} className="w-[805.45px]  " />
//                     </div>
//                   </motion.div>
//                 ) : null}
//               </div>
//               {/* </AnimatePresence> */}
//               <div className="flex flex-col gap-14 lg:gap-32">
//                 <div className="w-full lg:hidden px-[6%]">
//                   <img src={explore3} className="w-[805.45px]  " />
//                 </div>
//                 <div ref={boxRef1} className="">
//                   <ExploreItem
//                     title={"Business Financing"}
//                     sub={
//                       "Earn monthly passive income by investing in our highly rewarding business financing projects."
//                     }
//                     item1={
//                       "Halvest carefully selects a business that meets our criteria"
//                     }
//                     item2={
//                       "We carry out due diligence to determine business and owner credit worthiness"
//                     }
//                     item3={
//                       "The business is then assigned a credit rating and terms are agreed"
//                     }
//                     item4={
//                       "A supplier invoice is provided for the purchase of inventory or asset"
//                     }
//                     item5={"Campaign is listed on the Halvest investor portal"}
//                   />
//                 </div>
//                 <div className="w-full lg:hidden px-[6%]">
//                   <img src={explore} className="w-[805.45px]  " />
//                 </div>
//                 <div ref={boxRef2} className="lg:my-[5vh] ">
//                   <ExploreItem
//                     title={"Real Estate Investing"}
//                     sub={
//                       "Co-invest with fellow investors in our real estate projects in Dubai and the USA and receive monthly or quarterly rental income, all in dollars, without any interest."
//                     }
//                     item1={"Halvest selects a property in a prime location"}
//                     item2={
//                       "We create a special purpose vehicle (SPV company) to buy the property"
//                     }
//                     item3={"You own a % (share) of the SPV"}
//                     item4={
//                       "Management of property is assigned to a reliable and trustworthy property manager "
//                     }
//                     item5={"Property is put up for rental or on Airbnb"}
//                   />
//                 </div>
//                 <div className="w-full lg:hidden px-[6%]">
//                   <img src={explore2} className="w-[805.45px]  " />
//                 </div>
//                 <div ref={boxRef3} className="pb-16 mb-0 ">
//                   <ExploreItem
//                     title={"Venture Investing"}
//                     sub={
//                       "Join other angel investors to own equity in the next wave of ethical startups & SMEs using our ESG focused investing model."
//                     }
//                     item1={"Halvest selects a property in a prime location"}
//                     item2={
//                       "We create a special purpose vehicle (SPV company) to buy the property"
//                     }
//                     item3={"You own a % (share) of the SPV"}
//                     item4={
//                       "Management of property is assigned to a reliable and trustworthy property manager  "
//                     }
//                     item5={"Property is put up for rental or on Airbnb"}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Explore;
